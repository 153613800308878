<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink="dashboard/main">
        <!-- <img src="assets/images/logo.jpg" alt="" /> -->
        <span class="logo-name">Q2Birds</span>
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon>menu</mat-icon>
          </button>

          <span style="margin-left: 10px;font-weight: bold;font-size: 20px;">{{currentCompany.companyName}}</span>
          /<span style="margin-left: 10px;font-size: 15px;">{{selectedModule}}</span>
        </li>

      </ul>
      <ul class="nav navbar-nav navbar-right">

        <li class="nav-item user_profile" ngbDropdown>
          <div class="chip dropdown-toggle" ngbDropdownToggle class="nav-notification-icons pt-0">
            <mat-icon style="margin-top: 20px;">apps</mat-icon>
          </div>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li *ngIf="hasRole('USER_MANAGER')">
                      <a href="#" onClick="return false;" (click)="switchModule('profile')" >
                        <mat-icon aria-hidden="false" class="msr-2">account_circle</mat-icon>Users
                      </a>
                    </li>
                    <li *ngIf="hasRole('HEALTH_SCREENING_MANAGER')">
                      <a href="javascript:void(0);" onClick="return false;"  (click)="switchModule('screening')">
                        <mat-icon aria-hidden="false" class="msr-2">settings_system_daydream</mat-icon>Health Screening
                      </a>
                    </li>

                    <li *ngIf="hasRole('INTELLISTEPS_MANAGER')">
                      <a href="javascript:void(0);" disabled onClick="return false;" (click)="switchModule('steps')">
                        <mat-icon aria-hidden="false" class="msr-2">swap_calls</mat-icon>IntelliSteps
                      </a>
                    </li>

                    <li *ngIf="hasRole('MESSAGING_MANAGER')">
                      <a href="javascript:void(0);" onClick="return false;" (click)="switchModule('messaging')">
                        <mat-icon aria-hidden="false" class="msr-2">local_post_office</mat-icon>Messaging
                      </a>
                    </li>

                    <li *ngIf="hasRole('INVENTORY_MANAGER')">
                      <a href="javascript:void(0);" onClick="return false;" (click)="switchModule('inventory')">
                        <mat-icon aria-hidden="false" class="msr-2">grain</mat-icon>InventiQ
                      </a>
                    </li>

                    <li *ngIf="hasRole('REPORTS')">
                      <a href="javascript:void(0);" disabled onClick="return false;" (click)="switchModule('reports')">
                        <mat-icon aria-hidden="false" class="msr-2">bar_chart</mat-icon>Reports
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>

        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li>

        <li class="nav-item user_profile" ngbDropdown>
          <div class="chip dropdown-toggle" ngbDropdownToggle class="nav-notification-icons pt-0">
            <img src="assets/images/logo.png" class="rounded-circle" width="32" height="32" alt="User">
            <!--  <span>{{loginUser.fullName}}</span> -->
          </div>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                      <a href="#" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>Profile
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>