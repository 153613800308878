<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="nav navbar-nav navbar-right">

        <li class="nav-item user_profile" ngbDropdown>
          <div class="chip dropdown-toggle" ngbDropdownToggle class="nav-notification-icons pt-0">
            <mat-icon style="margin-top: 20px;">apps</mat-icon>
          </div>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li *ngIf="hasRole('USER_MANAGER')">
                      <a href="#" onClick="return false;" (click)="switchModule('profile')" >
                        <mat-icon aria-hidden="false" class="msr-2">account_circle</mat-icon>Users
                      </a>
                    </li>
                    <li *ngIf="hasRole('HEALTH_SCREENING_MANAGER')">
                      <a href="javascript:void(0);" onClick="return false;"  (click)="switchModule('screening')">
                        <mat-icon aria-hidden="false" class="msr-2">settings_system_daydream</mat-icon>Health Screening
                      </a>
                    </li>

                    <li *ngIf="hasRole('INTELLISTEPS_MANAGER')">
                      <a href="javascript:void(0);" disabled onClick="return false;" (click)="switchModule('steps')">
                        <mat-icon aria-hidden="false" class="msr-2">swap_calls</mat-icon>IntelliSteps
                      </a>
                    </li>

                    <li *ngIf="hasRole('REPORTS')">
                      <a href="javascript:void(0);" disabled onClick="return false;" (click)="switchModule('reports')">
                        <mat-icon aria-hidden="false" class="msr-2">bar_chart</mat-icon>Reports
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>

        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>