<mat-form-field class="example-full-width" appearance="outline">
    <mat-label>{{label}} </mat-label>
    <input type="text" name="product" matInput [formControl]="control" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" panelWidth="{{panelWidth}}">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="getValueField(option)">
            {{getMyValue(option)}}
        </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="control.hasError('required')">
        {{label}} is required
    </mat-error>
</mat-form-field>