import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({ 
  selector: "app-text-popup",
  templateUrl: "text-dialog.component.html",
  styleUrls: ["./text-dialog.component.scss"],
})
export class TextPopupComponent {

  constructor(private dialogRef: MatDialogRef<TextPopupComponent>,
     @Inject(MAT_DIALOG_DATA) public data: { message: string})  {
  }

  close(): void {
    this.dialogRef.close();
  }
}
