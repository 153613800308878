import { Component, Input, OnInit, SimpleChanges, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: "searchable-select",
  templateUrl: "./searchable.component.html",
})
export class SearchAbleSelectComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() options: any = [];
  @Input() displayField: string;
  @Input() additionalDisplayField: string = undefined;
  @Input() valueField: string = null;
  @Input() label: string = "select";
  @Input() panelWidth: string = "300px";
  @Output() messageEvent = new EventEmitter<any>();


  filteredOptions: Observable<any>;
  constructor() {}
  ngOnInit(): void {
    this.loadData();
  }

  getValueField(option:any):string {
    return this.valueField == null?option:option[this.valueField]
  }

  getMyValue(option: any): string {
    if (option == null || option == undefined || !option[this.displayField]) return "";

    let a = (this.additionalDisplayField != undefined && option[this.additionalDisplayField])? " "+option[this.additionalDisplayField] :"";
    return (option[this.displayField] )+ a;
  }

  displayFn(product: any): string {
    if(this.valueField == null) return this.getMyValue(product);
    if(!this.options) return "";
    const selectedOption = this.options.find(option => option[this.valueField] === product);
    return this.getMyValue(selectedOption);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadData();
  }

  private _filter(value: string): any {
    return this.options.filter(option =>  {
      if (this.getMyValue(option).toLowerCase().indexOf(value) >= 0) {
        return option;
      }
    });
  }


  loadData(): void {
    if (this.options == undefined || this.options.length == 0) {
      this.control.setValue("");
      return;
    }
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
  }


}









 

