import { ProperCasePipe } from './_pipes/proper-case.pipe';
import { ByteFormatPipe } from './_pipes/byte-format.pipe';
import { TimezoneSelectComponent } from './components/timezone-select/timezone.component';
import { TimezonePipe } from './_pipes/time-zone.pipe';
import { MatSpinnerOverlayComponent} from './components/overlay-spinner/spinner-overlay.component'
import { CountrySelectComponent} from './components/country-select/country.component'

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FeatherIconsModule } from './components/feather-icons/feather-icons.module';
import { MaterialModule } from './material.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SearchAbleSelectComponent} from './components/searchable-select/searchable.component'
import { TextPopupComponent} from './components/popup-dialog/text-dialog.component'

@NgModule({
  declarations: [MatSpinnerOverlayComponent,TextPopupComponent, CountrySelectComponent, TimezoneSelectComponent, TimezonePipe, ByteFormatPipe, SearchAbleSelectComponent, ProperCasePipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatTableModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSortModule,
    MatStepperModule,
    MatToolbarModule,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule,
    FeatherIconsModule,
    MatSpinnerOverlayComponent, 
    CountrySelectComponent,
    TimezoneSelectComponent,
    TimezonePipe,
    ByteFormatPipe,
    SearchAbleSelectComponent,
    ProperCasePipe,
    TextPopupComponent
  ],
})
export class SharedModule {}
