import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [

  {
    path: '/profile/company/list',
    title: 'Company',
    moduleName: 'profile',
    iconType: 'material-icons-two-tone',
    icon: 'contacts',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    roles: ['COMPANY_MANAGER'],
  },

  {
    path: '/profile/user/list',
    title: 'User',
    moduleName: 'profile',
    iconType: 'material-icons-two-tone',
    icon: 'supervised_user_circle',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    roles: ['USER_MANAGER'],
  },
  {
    path: '/steps/facility/list',
    title: 'Facility',
    moduleName: 'steps',
    iconType: 'material-icons-two-tone',
    icon: 'waves',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    roles: ['INTELLISTEPS_MANAGER'],
  },
  {
    path: '/steps/activity/list',
    title: 'Activity',
    moduleName: 'steps',
    iconType: 'material-icons-two-tone',
    icon: 'local_activity',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    roles: ['INTELLISTEPS_MANAGER'],
  },

  {
    path: '/steps/station/list',
    title: 'Station',
    moduleName: 'steps',
    iconType: 'material-icons-two-tone',
    icon: 'healing',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    roles: ['INTELLISTEPS_MANAGER'],
  },

  {
    path: '/steps/category/list',
    title: 'Category',
    moduleName: 'steps',
    iconType: 'material-icons-two-tone',
    icon: 'category',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    roles: ['INTELLISTEPS_MANAGER'],
  },

  {
    path: '/steps/service/list',
    title: 'Service',
    moduleName: 'steps',
    iconType: 'material-icons-two-tone',
    icon: 'vaccines',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    roles: ['INTELLISTEPS_MANAGER'],
  },

  {
    path: '/steps/packages/list',
    title: 'Packages',
    moduleName: 'steps',
    iconType: 'material-icons-two-tone',
    icon: 'payments',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    roles: ['INTELLISTEPS_MANAGER'],
  },

  {
    path: '/screening/patient/list',
    title: 'Patients',
    moduleName: 'screening',
    iconType: 'material-icons-two-tone',
    icon: 'accessible',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    roles: ['HEALTH_SCREENING_MANAGER'],
  },

  {
    path: '/screening/dashboard',
    title: 'Health Screening',
    moduleName: 'screening',
    iconType: 'material-icons-two-tone',
    icon: 'settings_system_daydream',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    roles: ['HEALTH_SCREENING_MANAGER'],
  },
  {
    path: '/steps/event/list',
    title: 'Event',
    moduleName: 'steps',
    iconType: 'material-icons-two-tone',
    icon: 'event',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    roles: ['INTELLISTEPS_MANAGER'],
  },
  {
    path: '/steps/messaging/list',
    title: 'Messaging',
    moduleName: 'messaging',
    iconType: 'material-icons-two-tone',
    icon: 'message',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    submenu: [],
    roles: ['MESSAGING_MANAGER'],
  },

  {
    path: "/inventory/cases/list",
    title: "Cases",
    moduleName: "inventory",
    iconType: "material-icons-two-tone",
    icon: "accessible",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [],
    roles: ["INVENTORY_MANAGER"],
  },

  {
    path: "/inventory/search-card",
    title: "Pick",
    moduleName: "inventory",
    iconType: "material-icons-two-tone",
    icon: "plagiarism",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [],
    roles: ["INVENTORY_MANAGER"],
  },

  {
    path: "/inventory/consignment-orders/list",
    title: "Consignment Order",
    moduleName: "inventory",
    iconType: "material-icons-two-tone",
    icon: "shopping_basket",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [],
    roles: ["INVENTORY_MANAGER"],
  },

  {
    path: "/inventory/surgeons/list",
    title: "Surgeons",
    moduleName: "inventory",
    iconType: "material-icons-two-tone",
    icon: "engineering",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [],
    roles: ["INVENTORY_MANAGER"],
  },
  {
    path: "/inventory/procedure-card/list",
    title: "Procedure Card",
    moduleName: "inventory",
    iconType: "material-icons-two-tone",
    icon: "card_membership",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [],
    roles: ["INVENTORY_MANAGER"],
  },
  {
    path: "/inventory/item/list",
    title: "Items",
    moduleName: "inventory",
    iconType: "material-icons-two-tone",
    icon: "pie_chart",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [],
    roles: ["INVENTORY_MANAGER"],
  },
 
  {
    path: "/inventory/media/list",
    title: "Media",
    moduleName: "inventory",
    iconType: "material-icons-two-tone",
    icon: "photo_library",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [],
    roles: ["INVENTORY_MANAGER"],
  },


 



  {
    path: '',
    title: "Settings",
    moduleName: "inventory",
    iconType: "material-icons-two-tone",
    icon: "settings",
    class: 'menu-toggle',
    groupTitle: false,
    badge: "",
    badgeClass: "",
    submenu: [
      {
        path: "/inventory/procedure/list",
        title: "Procedure",
        moduleName: "inventory1",
        iconType: "",
        icon: "",
        class: 'ml-menu',
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
        roles: ["INVENTORY_MANAGER"],
      },
      {
        path: "/inventory/category/list",
        title: "Item Category",
        moduleName: "inventory1",
        iconType: "material-icons-two-tone",
        icon: "category",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
        roles: ["INVENTORY_MANAGER"],
      },
      {
        path: "/inventory/location/list",
        title: "Location",
        moduleName: "inventory1",
        iconType: "material-icons-two-tone",
        icon: "location_on",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
        roles: ["INVENTORY_MANAGER"],
      },
      {
        path: "/inventory/sub-location/list",
        title: "Sub Location",
        moduleName: "inventory1",
        iconType: "",
        icon: "",
        class: 'ml-menu',
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
        roles: ["INVENTORY_MANAGER"],
      },
      {
        path: "/inventory/facility/list",
        title: "Facility",
        moduleName: "inventory1",
        iconType: "material-icons-two-tone",
        icon: "waves",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
        roles: ["INVENTORY_MANAGER"],
      },
      {
        path: "/inventory/unit/list",
        title: "Unit of Measure",
        moduleName: "inventory1",
        iconType: "material-icons-two-tone",
        icon: "event",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        submenu: [],
        roles: ["INVENTORY_MANAGER"],
      },
    ],
    roles: ["INVENTORY_MANAGER"],
  },
];
