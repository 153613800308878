import { TokenStorageService } from './token-storage.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
    this.currentUserSubject = new BehaviorSubject<User>(
      this.tokenStorageService.getUser()
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getUserRoles(): any {
    return this.tokenStorageService.getUser().roles;
  }

  getSelectedModule(): any {
    return this.tokenStorageService.getModule();
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/api/auth/login`, {
        username,
        password,
      })
      .pipe(
        map((user) => {
          this.tokenStorageService.saveUser(user);
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }


  forgetPassword(email: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/api/auth/forgot-password`, {
        email,
      })
      .pipe(
        map((message) => {
          console.log(message);          
          return message;
        })
      );
  }

  resetPassword(password: string, confirmPassword: string, token: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/api/auth/reset-password`, {
        password, confirmPassword, token
      })
      .pipe(
        map((message) => {
          return message;
        })
      );
  }

  confirmAccount(token: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/v1/api/auth/confirm-account`, {
        token
      })
      .pipe(
        map((message) => {
          console.log(message);          
          return message;
        })
      );
  }

  logout() {
    this.tokenStorageService.signOut();
    return of({ success: false });
  }

  hasRole(roleName:string) : boolean {
    let filtered = this.getUserRoles().filter((item) => item == roleName);
    return filtered.length > 0;
  }


  checkAccess(allowedRoles : Array<string>){
    let exist = false;
    allowedRoles.forEach(str => {
      let found = this.getUserRoles().filter((item) => item == str);
      exist = exist || (found.length > 0);
    });
    return exist;
  }


  isPasswordUpdateRequired() {
    return this.tokenStorageService.getUser().user.forcePasswordReset;
  }

  getUser(): any {
    return this.tokenStorageService.getUser().user;
  }

  getCompany(): any {
    return this.tokenStorageService.getUser().company;
  }
}
