import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer,  FormGroupDirective, UntypedFormControl } from '@angular/forms';
import { lastValueFrom, map, Observable, startWith } from 'rxjs';
import { HttpClient } from '@angular/common/http'; 

@Component({
  selector: 'country-select',
  templateUrl: './country.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CountrySelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() selected: any = '';

  options: any;
  filteredOptions: Observable<any[]>;

  constructor(private httpClient: HttpClient) { }

  async ngOnInit(): Promise<void> {
    this.options = await lastValueFrom(this.httpClient.get("assets/data/countries.json"))
    .catch((err) => { 
      console.log(err);
    });

    if(this.selected != '') {
      let val = this.options.find( x => x.code == this.selected );
      this.control.setValue(val);
    } 

    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter(name) : this.options.slice()))
    );
  }

  displayFn(value: any): string {
    return value.name;
  }


  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) >= 0
    );
  }

}
