import { Component, OnInit } from '@angular/core';
const document: any = window.document;

@Component({
  selector: 'app-full-screen-layout',
  templateUrl: './full-screen-layout.component.html',
  styleUrls: [],
})
export class FullScreenLayout implements OnInit {
  constructor() {}

  ngOnInit(): void {}

}
