import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "propercase",
})
export class ProperCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;
    return value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
}
