<mat-form-field class="example-full-width" appearance="outline">
  <mat-label>Country</mat-label>
  <input type="text" name = "country" matInput [formControl]="control" [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{option.name}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="control.hasError('required')">
    Country required
  </mat-error>
</mat-form-field>