import { DatePipe } from '@angular/common';
import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';
import { AuthService } from 'src/app/core/service/auth.service';

@Pipe(
    {
        name: 'timezone'
    })
export class TimezonePipe implements PipeTransform {

    constructor(private authService: AuthService) {
    }

    transform(value: string, format: string = 'dd MMM yyyy', ...args: any[]) {

        if(value == undefined || value == null) return;

        let company = this.authService.getCompany();
        let timeZone = company.timeZone;
        var datePipe = new DatePipe("en-US");
        var transformed = datePipe.transform(value+'Z', format, timeZone);
        return transformed;
    }
}