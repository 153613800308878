import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let isAuthenticated = this.authService.currentUserValue; 

    if(!isAuthenticated){
      this.router.navigate(['/authentication/signin']);
      return false;
    }

    let isPasswordUpdateRequired = this.authService.isPasswordUpdateRequired();
    
    if(isPasswordUpdateRequired){
      this.router.navigate(['/authentication/update-password']);
      return false;
    }

    let authorizedRouteRoles = route.data.modules as Array<string>;
    if (authorizedRouteRoles != undefined) {
      const authorized = this.authService.checkAccess(authorizedRouteRoles);
      if (!authorized) {
        this.router.navigateByUrl('/authentication/unauthorized');
      }
    }
    return true;
  }

  isAuthorized(userRoles, authorizedRole): boolean {
    return userRoles.some(r => authorizedRole.includes(r));
  }
}
